<template>
  <Modal v-model="inventorySyncRulesModal" :title="$t('key1004423')" width="480"
    :transfer="false" :mask-closable="false" @on-visible-change="inventorySyncRulesChange">
    <Form ref="inventorySyncRulesForm" :model="inventorySyncRulesForm" :label-width="80" @submit.native.prevent>
      <Row style="width: 90%;">
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <FormItem :label="configCharacters + $t('key1005976')" prop="rules" class="reset_form_item_style">
            <Select v-model="inventorySyncRulesForm.rules" transfer class="pl5" @on-change="rulesChange">
              <Option v-for="item in inventorySyncRulesList" :key="item.value" :value="item.value">{{ item.name }}</Option>
            </Select>
          </FormItem>
        </Col>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol" v-if="inventorySyncRulesData.length >0">
          <FormItem label="" prop="qty" class="reset_form_item_style">
            <div class="rules_box" v-for="(item,index) in inventorySyncRulesData" :key="index">
              <!--选择加减-->
              <Select v-model="item.typeValue" transfer style="width: 60px;" class="type_style" placeholder="">
                <Option value="+">+</Option>
                <Option value="-">-</Option>
              </Select>
              <!--规则选项-->
              <Select v-model="item.ruleValue" transfer class="ml12" style="flex:1;" @on-change="rulesChange">
                <Option v-for="(talg,idx) in item.ruleList" :key="idx" :value="talg.value">{{ talg.name }}</Option>
              </Select>
              <!--删除图标-->
              <Icon type="ios-trash" color="rgb(225 31 18)" size="24" class="delete_icon_style" @click="deleteRuleBtn(item, index)"/>
            </div>
          </FormItem>
        </Col>
        <h3 class="rule_title" v-if="inventorySyncRulesData.length < 7" @click="addRuleBtn">{{ $t('key1004312') }}</h3>
        <div class="select_rules_box" v-if="selectRulesTitle">{{ selectRulesTitle }}</div>
      </Row>
    </Form>
    <template #footer>
      <Button @click="inventorySyncRulesModal = false">{{ $t('key1000097') }}</Button>
      <Button type="primary" @click="inventorySyncRulesBtn">{{ $t('key1000348') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';

export default {
  name: "inventorySyncRulesModal",
  mixins: [Mixin],
  data() {
    return {
      inventorySyncRulesModal: false,
      inventorySyncRulesForm: {
        rules: 'qty',
      },
      originalData: [
        {value: 'qty', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005977')},
        {value: 'orderLock', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005978')},
        {value: 'pickLock', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005979')},
        {value: 'virtualQty', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005980')},
        {value: 'purchaseQty', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005981')},
        {value: 'returnQty', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005982')},
        {value: 'inQty', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005983')},
        {value: 'defectiveQty', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005984')}
      ],
      inventorySyncRulesList: [],
      inventorySyncRulesData: [],
      selectRulesTitle: '',
    }
  },
  methods: {
    // 查询同步库存规则的设置
    getInventorySyncRules() {
      let v = this;
      let paramValueList = [];
      let newList = [];
      v.axios.post(api.post_erpCommonSettingParam_queryMerchantByParamKeys, ['jushuitan']).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data.length > 0) {
            paramValueList = data[0].paramValue.split(',');
            v.inventorySyncRulesForm.rules = paramValueList[0];
            let selectKeyList = paramValueList.filter((value) => {
              if (!['+', '-'].includes(value)) {
                return value;
              }
            });
            let list = paramValueList.slice(1);
            if (list.length > 0) {
              list.map((item, index) => {
                let arr = null;
                if (index % 2 === 0) {
                  if (index === 0) {
                    arr = list.slice(0, index + 2);
                    if (arr.length > 0) {
                      newList.push({
                        typeValue: arr[0],
                        ruleValue: arr[1],
                        ruleList: []
                      })
                    }
                  } else {
                    arr = list.slice(index, index * 2);
                    if (arr.length > 0) {
                      newList.push({
                        typeValue: arr[0],
                        ruleValue: arr[1],
                        ruleList: []
                      })
                    }
                  }
                }
              });
              if (newList.length > 0) {
                v.handleSyncRules(selectKeyList, newList);
              }
            } else {
              v.handleSyncRules(selectKeyList);
            }
          } else {
            v.handleSyncRules([v.inventorySyncRulesForm.rules]);
          }
          v.inventorySyncRulesModal = true;
        }
      });
    },
    // 选择规则项
    rulesChange(value) {
      let v = this;
      let selectKeyList = v.inventorySyncRulesData.map((item) => {
        return item.ruleValue;
      });
      selectKeyList.unshift(v.inventorySyncRulesForm.rules);
      if (selectKeyList && selectKeyList.length > 0) {
        v.handleSyncRules(selectKeyList, v.inventorySyncRulesData);
      }
    },
    // 添加规则
    addRuleBtn() {
      let v = this;
      let objs = {
        ruleList: [],
        typeValue: '+',
        ruleValue: ''
      };
      let keyList = v.inventorySyncRulesData.map((item) => {
        return item.ruleValue
      });
      keyList.push(this.inventorySyncRulesForm.rules);
      objs.ruleList = v.originalData.filter((ele) => {
        if (!keyList.includes(ele.value)) {
          return ele.value;
        }
      })
      v.inventorySyncRulesData.push(objs);
    },
    // 移除规则选项
    deleteRuleBtn(data, index) {
      let v = this;
      v.inventorySyncRulesData.splice(index, 1);
      let selectKeyList = v.inventorySyncRulesData.map((item) => {
        return item.ruleValue;
      })
      selectKeyList.unshift(v.inventorySyncRulesForm.rules);
      if (selectKeyList && selectKeyList.length > 0) {
        v.handleSyncRules(selectKeyList, v.inventorySyncRulesData);
      }
    },
    // 处理同步库存规则的数据
    handleSyncRules(selectKeyList, newList) {
      let v = this;
      // 第一个规则项
      let arr1 = v.originalData.filter((ele) => {
        if (ele.value === v.inventorySyncRulesForm.rules) {
          return ele;
        }
      });
      let arr2 = v.originalData.filter((ele) => {
        if (!selectKeyList.includes(ele.value)) {
          return ele;
        }
      });
      v.inventorySyncRulesList = [...arr1, ...arr2];
      // 添加的规则项
      if (newList && newList.length > 0) {
        newList.map((item, idx) => {
          let arr1 = v.originalData.filter((ele) => {
            if (ele.value === item.ruleValue) {
              return ele;
            }
          });
          let arr2 = v.originalData.filter((ele) => {
            if (!selectKeyList.includes(ele.value)) {
              return ele;
            }
          });
          item['ruleList'] = [...arr1, ...arr2];
        });
        v.inventorySyncRulesData = newList;
      }
      // 处理展示已经选中的规则项
      let title2 = '';
      v.inventorySyncRulesData.map((item) => {
        v.originalData.map((ele) => {
          if (item.ruleValue === ele.value) {
            title2 += ' ' + item.typeValue + ' ' + ele.name;
          }
        })
      });
      let title1 = '';
      v.originalData.map((talg) => {
        if (talg.value === v.inventorySyncRulesForm.rules) {
          title1 = talg.name;
        }
      });
      v.selectRulesTitle = v.configCharacters + alias1bddc4d174174d47a6dfb0437dace856.t('key1005985') + title1 + title2;
    },
    // 同步库存规则确认按钮
    inventorySyncRulesBtn() {
      let v = this;
      let paramValueList = [v.inventorySyncRulesForm.rules];
      // 校验规则项不能为空
      for (let i = 0; i < v.inventorySyncRulesData.length; i++) {
        let item = v.inventorySyncRulesData[i];
        if (!item.ruleValue) {
          v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005986'));
          return false;
        } else {
          paramValueList.push(item.typeValue, item.ruleValue);
        }
      }
      let query = {
        paramBoList: [
          {
            paramValue: paramValueList.join(','),
            paramKey: 'jushuitan'
          }
        ]
      };
      v.axios.post(api.post_erpCommonSettingParam_saveMerchantSetting, query, {loading: true, loadingText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000212')}).then(res => {
        if (res.data.code === 0) {
          v.$Message.success(alias1bddc4d174174d47a6dfb0437dace856.t('key1000130'));
          v.inventorySyncRulesModal = false;
        }
      });
    },
    // 监听弹窗
    inventorySyncRulesChange(value) {
      if (!value) {
        this.inventorySyncRulesList = [];
        this.inventorySyncRulesData = [];
        this.selectRulesTitle = '';
        this.inventorySyncRulesForm.rules = 'qty';
        this.handleSyncRules([this.inventorySyncRulesForm.rules]);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.reset_form_item_style {
  margin-bottom: 14px;
}

.rule_title {
  color: #2D8CF0;
  font-size: 14px;
  cursor: pointer;
  margin: 0 0 16px 88px;
  width: 100%;
}

.select_rules_box {
  font-size: 12px;
  margin-left: 22px;
}

.rules_box {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 5px;
  margin-bottom: 14px;

  .type_style /deep/ .ivu-select-input {
    font-size: 15px;
  }

  .delete_icon_style {
    position: absolute;
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

  }
}
</style>